const EXPERIMENTAL_PROJECT_IDS = [
    "qYAlCmTIh1Y70gxn7uXh", // BG_Project_Test
    "SUpGBQVe1b2egVHEIcpG", // CC_Test_NYSM
];

const EXPERIMENTAL_USER_IDS = [
    "D1gS0WMtODeTNX9T5xE6KwvaGZC3", // bahaa
    "bRj6ctR0CYONUq8XENsOKcL0jgG2", // clifford
    "hDSOaohaLoZ8wAHiMNjZxTyfa2x1", // clifford
    "lcEt76vENQOjKSVHfUWALKT3IAY2", // wael alsayed
    "mCQWNPIuqkf1b1IaNRaBBJiPz122", // mohammad zeineddine
    "CkFRVdPvdWTI1dxAojNrvHLHC5m2", // Samer
    "bIuBq3GaW6fTiRwoxXrjEhOIzwO2", // Samer Olabi, samer@gmail.com
];

/**
 * Determines if experimental Feature should be used based on project and user criteria
 * @param {string} projectId - The ID of the project
 * @param {string} userId - The ID of the user
 * @param {string} projectName - The name of the project
 * @returns {boolean} - Whether experimental Feature should be used
 */
const useExperimentalFeature = (projectId, userId, projectName) => {
// Invalid input check
if (!projectId || !userId || !projectName) {
    return false;
}

// Case 1: User is in experimental list AND project is in experimental list
const isExperimentalUserAndProject = 
    EXPERIMENTAL_USER_IDS.includes(userId) && 
    EXPERIMENTAL_PROJECT_IDS.includes(projectId);

// Case 2: User is in experimental list AND project name contains '_exp'
const isExperimentalUserAndName = 
    EXPERIMENTAL_USER_IDS.includes(userId) && 
    projectName.includes('_exp');

// Return true if either condition is met
return isExperimentalUserAndProject || isExperimentalUserAndName;
};

export default useExperimentalFeature;