import React, {
    useEffect,
} from "react";

import {
    Button
} from "@mui/material";
import { toast } from "react-toastify";

const WarningToastWithOverlay = ({ message, onConfirm, toastId }) => {
    useEffect(() => {
        const overlay = document.createElement('div');
        overlay.id = 'toast-overlay';
        overlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 20vh;
      `;
        document.body.appendChild(overlay);

        return () => {
            document.body.removeChild(overlay);
        };
    }, []);

    return (
        <div style={{
            position: 'relative',
            zIndex: 1001,
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '100%',
            maxWidth: '400px',
            padding: '16px'
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: '16px'
            }}>
                <p style={{
                    margin: 0,
                    fontSize: '18px',
                    color: '#333'
                }}>
                    {message}
                </p>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: '8px'
                }}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => toast.dismiss(toastId)}
                        style={{
                            textTransform: 'uppercase',
                            padding: '6px 16px',
                            fontSize: '13px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={async () => {
                            toast.dismiss(toastId);
                            await onConfirm();
                        }}
                        style={{
                            backgroundColor: '#1a3867',
                            textTransform: 'uppercase',
                            padding: '6px 16px',
                            fontSize: '13px'
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WarningToastWithOverlay;