import { Grid, Slider } from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/SFIScreen.module.css";
import Loader from "../../Components/Loader";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Store } from "../../Store";
import {
  ADD_FACE_DIMENSION,
  // DELETE_FACE_DIMENSION,
  GET_ALL_FACE_DIMENSIONS,
  GET_CHARACTERS_FOR_PROJECT,
  MERGING_VIDEO_API,
} from "../../MProjectConst";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import VideoSliderLips from "../../Components/AudioTools/VideoSliderLips";
import {
  formatInputs,
  formatTime,
  getLanguageName,
  processPathUrl,
  timeStringToSeconds,
} from "../../utils/generalFunctions";
import { ToastContainer, toast } from "react-toastify";
import TuneIcon from "@mui/icons-material/Tune";
import { secondaryColor, primaryColor } from "../../utils/constants";
import TaskButton from "../../Components/Buttons/TaskButton";

//SpeakerFaceIdentification Screen
const SFIScreen = () => {
  // const navigate = useNavigate();
  const { state } = useContext(Store);
  const { projectFile, userInfo } = state;
  // const videoFPS = projectFile.originalVideoDetails?.videoFPS
  //   ? projectFile.originalVideoDetails.videoFPS
  //   : 24;
  const originalWidth = projectFile.originalVideoDetails?.width
    ? projectFile.originalVideoDetails.width
    : 600;
  const originalHeight = projectFile.originalVideoDetails?.height
    ? projectFile.originalVideoDetails.height
    : 337;

  const [videoFPS, setVideoFPS] = useState();
  // projectFile.originalVideoDetails?.videoFPS
  //   ? projectFile.originalVideoDetails.videoFPS
  //   : 24

  const [audioData, setAudioData] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  const [audioLoader, setAudioLoader] = useState(false);
  const [versionName, setVersionName] = useState("");
  const [audioVersionSelector, setAudioVersionSelector] = useState("");
  const [videoVersionSelector, setVideoVersionSelector] = useState("");
  const [languageSelector, setLanguageSelector] = useState("");
  const [characters, setCharacters] = useState([]);
  const [selectedDialoguePanel, setSelectedDialoguePanel] = useState(false);
  const [frameTime, setFrameTime] = useState(0);
  const [frameTimeSwitch, setFrameTimeSwitch] = useState(false);
  const [frameTimeResults, setFrameTimeResults] = useState(0);
  const [frameTimeResultsSwitch, setFrameTimeResultsSwitch] = useState(0);
  const [boxDimensionsResults, setBoxDimensionsResults] = useState();
  const [deleteResultFrame, setDeleteResultFrame] = useState(null);
  const [selectCharacterFace, setSelectCharacterFace] = useState(0);

  const [currentAvailableFramesInBoxes, setCurrentAvailableFramesInBoxes] =
    useState(0);

  // const [squareDimensions, setSquareDimensions] = useState({
  //   left: "",
  //   top: "",
  //   width: "",
  //   height: "",
  // });

  const [rawBoundingBoxes, setRawBoundingBoxes] = useState(null);
  const [selectedCharacterBBox, setSelectedCharacterBBox] = useState([]);
  const [filterType, setFilterType] = useState("displayAll");

  // pagination items
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(audioData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = audioData.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };
  //-------------------------------------

  const containerRef = useRef(null);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  function findDisplayName(speaker) {
    const result = characters.find((char) => char.speaker === speaker);

    if (result.displayName) {
      return result.displayName;
    }
    return speaker;
  }

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  function checkTimeInRange(currentTime) {
    if (audioData) {
      const itemInTimeRange = audioData.find((item) => {
        // const { start, end } = item.textdetails;
        return currentTime >= item.start && currentTime < item.end;
      });

      if (itemInTimeRange) {
        if (itemInTimeRange.id !== scrollableItemLastUpdated) {
          setScrollableItemLastUpdated(itemInTimeRange.id);
        }
      }
    }
  }

  function filterByEmptyCharacterFrameBoxes() {
    let updatedData = [...audioData];

    updatedData.forEach((data) => {
      data.filterShow = false;
    });

    updatedData.forEach((data) => {
      // console.log(data.characterFrameBoxes);
      if (data.characterFrameBoxes.length === 0) {
        data.filterShow = true;
      } else {
        data.filterShow = false;
      }
    });

    setAudioData(updatedData);
  }
  function filterByDisplayAll() {
    let updatedData = [...audioData];

    updatedData.forEach((data) => {
      data.filterShow = true;
    });

    setAudioData(updatedData);
  }

  function addFrameBoxesToDialogues(dialogues, frameBoxes, videoFPS) {
    if (!Array.isArray(frameBoxes)) {
      console.error("frameBoxes is not an array.");
      return dialogues;
    }

    dialogues.forEach((dialogue) => {
      const dialogueStartTime = dialogue.start;
      const dialogueEndTime = dialogue.end;

      const dialogueStartTimeInFrames = Math.floor(
        dialogueStartTime * videoFPS
      );
      const dialogueEndTimeInFrames = Math.floor(dialogueEndTime * videoFPS);

      // Filter frame boxes that fall within the dialogue time
      const dialogueFrameBoxes = frameBoxes.filter((frameBox) => {
        const frameIndex = frameBox.frame_index;
        return (
          frameIndex > dialogueStartTimeInFrames &&
          frameIndex < dialogueEndTimeInFrames
        );
      });

      // dialogueFrameBoxes.forEach((frameBox) => {
      //   frameBox.frame_index = frameBox.frame_index - dialogueStartTimeInFrames;
      // });

      // Add the filtered frame boxes to the dialogue
      dialogue.frameBoxes = dialogueFrameBoxes;
    });

    return dialogues;
  }

  function handleStartTimeChange(e, index) {
    const updatedData = [...audioData];
    updatedData[index] = {
      ...updatedData[index],
      start: timeStringToSeconds(e.target.value),
    };

    setAudioData(updatedData);
  }
  function handleEndTimeChange(e, index) {
    const updatedData = [...audioData];

    updatedData[index] = {
      ...updatedData[index],
      end: timeStringToSeconds(e.target.value),
    };
    setAudioData(updatedData);
  }

  //+ adding api
  const AddNewFaceBoxesToDialogue = async (index) => {
    setAudioLoader(true);
    // if (boxDimensionsResults.length <= 0) {
    //   toast.error("Error! no frames in the result slider");
    //   setAudioLoader(false);
    //   return;
    // }
    let updatedData = [...audioData];
    updatedData[index].characterFrameBoxes = boxDimensionsResults;

    try {
      const response = await axios.post(ADD_FACE_DIMENSION, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
      if (response.data) {
        updatedData = updatedData.map((object) => ({
          ...object,
          add: false,
          // viewFrames: false,
        }));
        setFrameTime(0);
        setFrameTimeResults(0);
        // setSelectedCharacterBBox([]);
        // setBoxDimensionsResults([]);
        setAudioData(updatedData);
        setAudioLoader(false);
        setSelectedDialoguePanel(false);
      }
    } catch (err) {
      console.log(err);

      toast.error("Error in adding the new dialogue");

      setAudioLoader(false);
    }
  };

  //api final submission
  const finalSubmission = async () => {
    if (!versionName) {
      toast.error("Enter an version name");
      return;
    }

    if (!languageSelector) {
      toast.error("Select the language for the final submission");
      return;
    }

    // console.log(audioVersionSelector);
    const audioUrl = await processPathUrl(audioVersionSelector);

    if (audioUrl === "loading") {
      toast.error("The selected version audio is not loaded yet");
      return;
    }

    toast.success("New version is being processed");
    try {
      const response = await axios.post(MERGING_VIDEO_API, {
        projectId: projectFile.id,
        language: languageSelector,
        version: versionName,
        audioUrl: audioUrl,
        videoUrl: projectFile.videoUrl,
        projectName: projectFile.name,
        emails: [userInfo.email, "wissam_just@outlook.com", "ak@y76.io"],
        submitterName: userInfo.name
      });
      if (response.data) {
        console.log(response.data);
      }
      setVersionName("");
      setAudioVersionSelector("");
    } catch (err) {
      console.log(err);
    }
  };

  // apis - dialogue
  useEffect(() => {
    const fetchData = async () => {
      setAudioLoader(true);
      try {
        let frameBoxesData;

        const response = await axios.post(GET_ALL_FACE_DIMENSIONS, {
          projectId: projectFile.id,
        });

        const url = await processPathUrl(response.data.faceBoxesPath);

        if (url) {
          try {
            const response = await axios.get(url);
            frameBoxesData = response.data;
          } catch (error) {
            toast.error("Frame boxes are not found!!");
            console.log("Error in fetching Frame boxes", error);
          }
        }
        // console.log(response.data.frameBoxesData);

        if (response.data.frameBoxesData <= 0) {
          toast.error("Frame boxes are not found!!");
        }

        let sortedArray = await response.data.projectsArray.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        sortedArray.forEach((dia, index) => {
          dia.id = index;
          dia.selected = false;
          dia.filterShow = true;
        });

        sortedArray = addFrameBoxesToDialogues(
          sortedArray,
          frameBoxesData,
          response.data.videoFPS
        );
        setRawBoundingBoxes(frameBoxesData);

        setAudioData(sortedArray);
        // console.log(response.data);
        setVideoFPS(response.data.videoFPS);
        setAudioLoader(false);
      } catch (err) {
        setAudioLoader(false);
        toast.error("Frame boxes are not found!!");
        console.log(err);
      }
    };
    fetchData();
  }, []);

  //apis - get characters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_CHARACTERS_FOR_PROJECT, {
          projectId: projectFile.id,
        });

        setCharacters(response.data.characters);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //top, right, bottom, and left

  return (
    <Grid
      item
      container
      justifyContent="center"
      marginTop={0.5}
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      {/* {console.log(videoFPS)} */}

      <Loader Load={audioLoader} />
      <ToastContainer position="top-center" theme="dark" />
      <Grid item container xs={10.5} direction="column">
        <Grid item sx={{ fontWeight: "600" }}>
          Speaker Face Identification
        </Grid>
        <Grid item container direction="row">
          <VideoSliderLips
            source={projectFile.videoUrl}
            seekTime={seekTime}
            seekTimeIndex={seekTimeIndex}
            displayAudioTime={displayAudioTime}
            originalWidth={originalWidth}
            originalHeight={originalHeight}
            videoFPS={videoFPS}
            frameTimeSwitch={frameTimeSwitch}
            frameTimeResultsSwitch={frameTimeResultsSwitch}
            boxDimensionsResults={boxDimensionsResults}
            setBoxDimensionsResults={setBoxDimensionsResults}
            deleteResultFrame={deleteResultFrame}
            selectedCharacterBBox={selectedCharacterBBox}
            selectCharacterFace={selectCharacterFace}
            rawBoundingBoxes={rawBoundingBoxes}
            selectedDialoguePanel={selectedDialoguePanel}
          // SquareDimensions={setSquareDimensions}
          />

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            // xs={5.85}
            direction="column"
            marginTop={1.5}
          >
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              //   marginTop={1}
              direction="row"
              height="815px"
            >
              <Grid
                item
                container
                alignContent="center"
                justifyContent="center"
                direction="row"
                sx={{ borderBottom: "1px solid #ebebeb" }}
                marginTop={1}
                gap={0.5}
              >
                <Grid
                  item
                  container
                  direction="row"
                  marginLeft={0}
                  marginTop={1}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    paddingTop={0.5}
                    xs={11.3}
                    height="100px"
                    marginBottom={3}
                    justifyContent="space-between"
                    // justifyContent="center"
                    gap={1}
                    bgcolor="#EBEBEB"
                  >
                    {selectedDialoguePanel ? (
                      <>
                        <Grid
                          xs={2}
                          item
                          container
                          direction="column"
                          alignContent="center"
                          marginLeft={3}
                        >
                          <Grid item marginBottom={0.5}>
                            <Grid item fontWeight={900}>
                              Information
                            </Grid>
                            <Grid item>
                              Name: {" " + selectedDialoguePanel.speaker}
                            </Grid>
                            <Grid item>
                              Start Time:{" "}
                              {" " + formatTime(selectedDialoguePanel.start)}
                            </Grid>
                            <Grid item>
                              End Time:{" "}
                              {" " + formatTime(selectedDialoguePanel.end)}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={2}
                          item
                          container
                          direction="column"
                          alignContent="center"
                        >
                          {/* {console.log(
                            selectedDialoguePanel.characterFrameBoxes
                          )} */}
                          {/* {console.log(selectedDialoguePanel.frameBoxes)} */}

                          <Grid item marginBottom={0.5}>
                            <Grid item fontWeight={900}>
                              Frame Time Slider
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Slider
                              value={frameTime}
                              onChange={(e) => {
                                setFrameTime(e.target.value);
                                setSeekTime(
                                  e.target.value + selectedDialoguePanel.start
                                );

                                setFrameTimeSwitch(true);

                                setTimeout(() => {
                                  setFrameTimeSwitch(false);
                                }, 1000);
                              }}
                              min={0}
                              max={
                                selectedDialoguePanel.end -
                                selectedDialoguePanel.start
                              }
                              step={0.01}
                              valueLabelDisplay="auto"
                              aria-label="Volume"
                              sx={{ color: `${secondaryColor}` }}
                            // marks
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              Frames :{" " + Math.floor(frameTime * videoFPS)}
                            </Grid>
                            <Grid item container xs={6}>
                              <Grid item xs={3.5}>
                                <TaskButton
                                  disabled={frameTime <= 0}
                                  onClick={() => {
                                    if (frameTime >= 0) {
                                      setFrameTime(frameTime - 1 / videoFPS);
                                      // console.log((1 / videoFPS).toFixed(4));
                                      setSeekTime(
                                        frameTime -
                                        1 / videoFPS +
                                        selectedDialoguePanel.start
                                      );
                                      setFrameTimeSwitch(true);

                                      setTimeout(() => {
                                        setFrameTimeSwitch(false);
                                      }, 1000);
                                    }
                                  }}
                                >
                                  {"<"}
                                </TaskButton>
                              </Grid>
                              <Grid item xs={6}>
                                <TaskButton
                                  disabled={
                                    frameTime >
                                    selectedDialoguePanel.end -
                                    selectedDialoguePanel.start
                                  }
                                  onClick={() => {
                                    if (
                                      frameTime <
                                      selectedDialoguePanel.end -
                                      selectedDialoguePanel.start
                                    ) {
                                      setFrameTime(frameTime + 1 / videoFPS);
                                      // console.log(1 / videoFPS);

                                      setSeekTime(
                                        frameTime +
                                        1 / videoFPS +
                                        selectedDialoguePanel.start
                                      );
                                      setFrameTimeSwitch(true);

                                      setTimeout(() => {
                                        setFrameTimeSwitch(false);
                                      }, 1000);
                                    }
                                  }}
                                >
                                  {">"}
                                </TaskButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          xs={1}
                          item
                          container
                          direction="column"
                          alignItems="center"
                          gap={0.2}
                        >
                          <Grid item fontWeight={900}>
                            Selection
                          </Grid>

                          <Grid
                            item
                            sx={{
                              cursor: "pointer",
                              color: false ? "grey" : `${secondaryColor}`,
                              "&:hover": {
                                color: `${primaryColor}`,
                              },
                            }}
                          >
                            <AddCircleIcon
                              onClick={() =>
                                setSelectCharacterFace(1 + selectCharacterFace)
                              }
                              fontSize="large"
                            />
                          </Grid>
                          <Grid item>
                            <TaskButton
                              onClick={() => {
                                setBoxDimensionsResults([]);
                              }}
                            >
                              Clear
                            </TaskButton>
                          </Grid>
                        </Grid>

                        <Grid
                          xs={3}
                          item
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item container marginBottom={0.5}>
                            <Grid item fontWeight={900}>
                              Face Tracker results
                            </Grid>
                            <Grid item>
                              {": " + boxDimensionsResults.length}
                            </Grid>
                          </Grid>

                          <Grid item width="85%">
                            <Slider
                              value={frameTimeResults}
                              onChange={(e) => {
                                setFrameTimeResults(e.target.value);
                                setSeekTime(
                                  e.target.value + selectedDialoguePanel.start
                                );

                                setFrameTimeResultsSwitch(true);

                                setTimeout(() => {
                                  setFrameTimeResultsSwitch(false);
                                }, 1000);
                              }}
                              min={0}
                              max={
                                selectedDialoguePanel.end -
                                selectedDialoguePanel.start
                              }
                              step={0.01}
                              // marks
                              valueLabelDisplay="auto"
                              aria-label="Volume"
                              sx={{ color: `${secondaryColor}` }}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            direction="row"
                            gap={2}
                            alignItems="center"
                          >
                            <Grid item xs={3.5}>
                              Frames :
                              {" " + Math.floor(frameTimeResults * videoFPS)}
                            </Grid>
                            <Grid item container xs={2.8}>
                              <Grid item xs={6}>
                                <TaskButton
                                  disabled={frameTimeResults <= 0}
                                  onClick={() => {
                                    if (frameTimeResults >= 0) {
                                      setFrameTimeResults(
                                        frameTimeResults - 1 / videoFPS
                                      );
                                      setSeekTime(
                                        frameTimeResults -
                                        1 / videoFPS +
                                        selectedDialoguePanel.start
                                      );
                                    }

                                    setFrameTimeResultsSwitch(true);

                                    setTimeout(() => {
                                      setFrameTimeResultsSwitch(false);
                                    }, 1000);
                                  }}
                                >
                                  {"<"}
                                </TaskButton>
                              </Grid>
                              <Grid item xs={6}>
                                <TaskButton
                                  disabled={
                                    frameTimeResults >
                                    selectedDialoguePanel.end -
                                    selectedDialoguePanel.start
                                  }
                                  onClick={() => {
                                    if (
                                      frameTimeResults <
                                      selectedDialoguePanel.end -
                                      selectedDialoguePanel.start
                                    ) {
                                      setFrameTimeResults(
                                        frameTimeResults + 1 / videoFPS
                                      );
                                      setSeekTime(
                                        frameTimeResults +
                                        1 / videoFPS +
                                        selectedDialoguePanel.start
                                      );
                                      setFrameTimeResultsSwitch(true);

                                      setTimeout(() => {
                                        setFrameTimeResultsSwitch(false);
                                      }, 1000);
                                    }
                                  }}
                                >
                                  {">"}
                                </TaskButton>
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <TaskButton
                                onClick={() => {
                                  setDeleteResultFrame(frameTimeResults);
                                }}
                              >
                                Delete
                              </TaskButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={3.3}
                          item
                          container
                          direction="column"
                          alignContent="center"
                        >
                          <Grid item container marginBottom={0.5}>
                            <Grid item fontWeight={900}>
                              Available faces per frames
                            </Grid>
                            <Grid item>
                              {": " + boxDimensionsResults.length}
                            </Grid>
                          </Grid>

                          <Grid item width="85%">
                            <Slider
                              value={currentAvailableFramesInBoxes}
                              onChange={(e) => {
                                if (boxDimensionsResults.length >= 1) {
                                  setCurrentAvailableFramesInBoxes(
                                    e.target.value
                                  );
                                  setSeekTime(e.target.value / videoFPS);
                                  setFrameTimeResultsSwitch(true);
                                  setTimeout(() => {
                                    setFrameTimeResultsSwitch(false);
                                  }, 1000);
                                }
                              }}
                              min={boxDimensionsResults[0]?.frame}
                              max={
                                boxDimensionsResults.length >= 1
                                  ? boxDimensionsResults[
                                    boxDimensionsResults?.length - 1
                                  ]?.frame
                                  : 0
                              }
                              step={1}
                              // marks
                              valueLabelDisplay="auto"
                              aria-label="Volume"
                              sx={{ color: `${secondaryColor}` }}
                            />
                          </Grid>

                          <Grid
                            item
                            container
                            direction="row"
                            gap={2}
                            alignItems="center"
                          >
                            <Grid item xs={5.7}>
                              Video Frame :{" " + currentAvailableFramesInBoxes}
                            </Grid>
                            <Grid item container xs={2.5}>
                              <Grid item xs={6}>
                                <TaskButton
                                  onClick={() => {
                                    if (
                                      boxDimensionsResults[0]?.frame <=
                                      currentAvailableFramesInBoxes
                                    ) {
                                      setCurrentAvailableFramesInBoxes(
                                        currentAvailableFramesInBoxes - 1
                                      );

                                      setSeekTime(
                                        (currentAvailableFramesInBoxes - 1) /
                                        videoFPS
                                      );

                                      setFrameTimeResultsSwitch(true);

                                      setTimeout(() => {
                                        setFrameTimeResultsSwitch(false);
                                      }, 1000);
                                    }
                                  }}
                                >
                                  {"<"}
                                </TaskButton>
                              </Grid>
                              <Grid item xs={6}>
                                <TaskButton
                                  onClick={() => {
                                    if (
                                      boxDimensionsResults[0]?.frame >=
                                      currentAvailableFramesInBoxes
                                    ) {
                                      setCurrentAvailableFramesInBoxes(
                                        currentAvailableFramesInBoxes + 1
                                      );

                                      setSeekTime(
                                        (currentAvailableFramesInBoxes + 1) /
                                        videoFPS
                                      );

                                      setFrameTimeResultsSwitch(true);

                                      setTimeout(() => {
                                        setFrameTimeResultsSwitch(false);
                                      }, 1000);
                                    }
                                  }}
                                >
                                  {">"}
                                </TaskButton>
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <TaskButton
                                onClick={() =>
                                  setDeleteResultFrame(
                                    currentAvailableFramesInBoxes
                                  )
                                }
                              >
                                Delete
                              </TaskButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        item
                        container
                        sx={{ fontWeight: "600", fontSize: "20px" }}
                        justifyContent="center"
                        alignContent="center"
                        marginLeft={8}
                      >
                        <Grid item> No Selected Dialogue</Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    // alignContent="center"
                    marginLeft={2}
                    marginTop={3}
                  >
                    <Grid
                      item
                      onClick={() => {
                        AddNewFaceBoxesToDialogue(selectedDialoguePanel.id);
                      }}
                      sx={{
                        cursor: "pointer",
                        color: `${secondaryColor}`,
                        "&:hover": {
                          color: `${primaryColor}`,
                        },
                      }}
                    >
                      <SaveIcon fontSize="large" />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    // justifyContent="center"
                    alignItems="center"
                    gap={4}
                    marginTop={-2}
                  >
                    {/* <Grid
                      item
                      container
                      xs={0.5}
                      alignItems="center"
                      // justifyContent="flex-end"
                    >
                      <Grid
                        item
                        sx={{
                          color: "#5570DC",
                        }}
                        marginTop={0.6}
                      >
                        <TuneIcon fontSize="small" />
                      </Grid>
                      <Grid item>Filter</Grid>
                    </Grid> */}

                    {/* <Grid item xs={8.5} container gap={5}>
                      <label>
                        <input
                          type="radio"
                          checked={filterType === "displayAll"}
                          onChange={() => {
                            filterByDisplayAll();
                            setFilterType("displayAll");
                          }}
                        />
                        Display All
                      </label>

                      <label>
                        <input
                          type="radio"
                          checked={filterType === "emptyCharacterFrameBoxes"}
                          onChange={() => {
                            filterByEmptyCharacterFrameBoxes();
                            setFilterType("emptyCharacterFrameBoxes");
                          }}
                        />
                        Empty Translated Text
                      </label>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid
                  item
                  container
                  direction="row"
                  marginBottom={4}
                  marginTop={2}
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  maxHeight={{
                    xl: "800px",
                    lg: "550px",
                    md: "800px",
                    xs: "560px",
                  }}
                  height="610px"
                  ref={containerRef}
                >
                  {audioData.length > 0 ? (
                    <>
                      {currentItems.map((data, index) => (
                        <React.Fragment key={index}>
                          {data.filterShow && (
                            <Grid
                              item
                              container
                              direction="row"
                              key={index}
                              marginLeft={3.5}
                              marginBottom={2}
                            >
                              <Grid
                                xs={1.8}
                                item
                                container
                                direction="column"
                                style={{
                                  backgroundColor: data.selected
                                    ? `${primaryColor}`
                                    : `${secondaryColor}`,
                                  color: !data.selected
                                    ? `${primaryColor}`
                                    : `${secondaryColor}`,
                                }}
                                justifyContent="center"
                                alignContent="center"
                                height="60px"
                              >
                                <Grid
                                  item
                                  sx={{
                                    color: "white",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {findDisplayName(data.speaker)}
                                  {data.characterFrameBoxes.length
                                    ? " - " +
                                    data.characterFrameBoxes.length +
                                    " F"
                                    : ""}
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                container
                                className={classes["AudioWorkPlaceBoxes"]}
                                direction="row"
                                paddingLeft={4}
                                paddingRight={4}
                                paddingTop={0.5}
                                xs={9.55}
                                onClick={() => {
                                  setSeekTime(data.start);
                                  setSeekTimeIndex(seekTimeIndex + 1);
                                  setSelectedDialoguePanel(data);
                                  setSelectedCharacterBBox(data.frameBoxes);
                                  setBoxDimensionsResults(
                                    data.characterFrameBoxes
                                  );
                                  setFrameTime(0);
                                  setFrameTimeResults(0);
                                  //
                                  let updatedData = [...audioData];
                                  updatedData.forEach((data) => {
                                    data.selected = false;
                                  });
                                  updatedData[data.id] = {
                                    ...updatedData[data.id],
                                    selected: !updatedData[data.id].selected,
                                  };
                                  setAudioData(updatedData);
                                  //
                                }}
                                sx={{ cursor: "pointer" }}
                                height="60px"
                                justifyContent="space-evenly"
                                gap={1}
                              >
                                <Grid xs={2} item container direction="column">
                                  <Grid
                                    item
                                    alignContent="center"
                                    marginBottom={1}
                                  >
                                    <Grid item fontWeight={900}>
                                      Start Time
                                    </Grid>
                                  </Grid>

                                  <Grid item>
                                    <input
                                      className={
                                        classes["AudioWorkPlaceTimelineEdit"]
                                      }
                                      value={formatTime(data.start)}
                                      type="text"
                                      onChange={(e) =>
                                        handleStartTimeChange(e, data.id)
                                      }
                                      style={{
                                        color:
                                          currentTime >= data.start &&
                                            currentTime < data.end
                                            ? "red"
                                            : "grey",
                                        width: "80%",
                                        background: "#EBEBEB",
                                        height: "22px",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                      }}
                                    ></input>
                                  </Grid>
                                </Grid>
                                <Grid xs={2} item container direction="column">
                                  <Grid
                                    item
                                    alignContent="center"
                                    marginBottom={1}
                                  >
                                    <Grid item marginTop={0} fontWeight={900}>
                                      End Time
                                    </Grid>
                                  </Grid>

                                  <Grid item>
                                    <input
                                      className={
                                        classes["AudioWorkPlaceTimelineEdit"]
                                      }
                                      value={formatTime(data.end)}
                                      type="text"
                                      onChange={(e) =>
                                        handleEndTimeChange(e, data.id)
                                      }
                                      style={{
                                        color:
                                          currentTime >= data.start &&
                                            currentTime < data.end
                                            ? "red"
                                            : "grey",
                                        width: "80%",
                                        background: "#EBEBEB",
                                        height: "22px",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                      }}
                                    ></input>
                                  </Grid>
                                </Grid>
                                <Grid xs={2} item container direction="column">
                                  <Grid
                                    item
                                    marginTop={0}
                                    fontWeight={900}
                                    marginBottom={1}
                                  >
                                    Dialogue Time
                                  </Grid>

                                  <Grid item>
                                    {(data.end - data.start).toFixed(2)} Seconds
                                  </Grid>
                                </Grid>
                                {/* <Grid
                                xs={2}
                                item
                                container
                                direction="column"
                                alignContent="center"
                              >
                                <Grid item marginBottom={0.5}>
                                  <Grid item fontWeight={900}>
                                    Frame Time Slider
                                  </Grid>
                                </Grid>

                                <Grid item marginBottom={0}>
                                  <Slider
                                  value={frameTime}
                                  onChange={(e) => {
                                    setFrameTime(e.target.value);
                                    setSeekTime(
                                      (
                                        Number(e.target.value) + data.start
                                      ).toFixed(2)
                                    );
                                  }}
                                  min={0}
                                  max={(data.end - data.start - 0.01).toFixed(
                                    2
                                  )}
                                  step={0.01}
                                  valueLabelDisplay="auto"
                                  aria-label="Volume"
                                  sx={{ color: "red" }}
                                  />
                                </Grid>
                                <Grid item marginLeft={4}>
                                  Frames :
                                  {(
                                    (currentTime - data.start) *
                                    videoFPS
                                  ).toFixed(0)}
                                </Grid>
                              </Grid> */}
                              </Grid>
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={2}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={3}
              >
                <Grid item>
                  <TaskButton
                    width="80px"
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </TaskButton>
                </Grid>

                <Grid item>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                </Grid>
                <Grid item>
                  <TaskButton
                    width="80px"
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </TaskButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container alignItems="center" justifyContent="center">
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
                marginTop={1}
              >
                <Grid item>Go</Grid>

                <Grid item>
                  <input
                    type="text"
                    style={{ width: "30px" }}
                    value={currentPage}
                    onChange={(e) =>
                      setCurrentPage(
                        Number(
                          e.target.value <= totalPages
                            ? e.target.value >= 1
                              ? e.target.value
                              : 1
                            : totalPages
                        )
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={8}
            gap={5}
          >
            <Grid item xs={0.8}>
              <select
                value={languageSelector}
                onChange={(e) => setLanguageSelector(e.target.value)}
                className={classes["AudioWorkSelector"]}
              >
                <option value="">select language</option>
                {projectFile?.finalLang?.map((lang, index) => (
                  <option value={lang} key={index}>
                    {getLanguageName(lang)}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={1}>
              <select
                value={audioVersionSelector}
                onChange={(e) => setAudioVersionSelector(e.target.value)}
                className={classes["AudioWorkSelector"]}
              >
                <option value="">select audio version</option>
                {projectFile?.dubbedAudiosList?.map(
                  (audio, index) =>
                    audio.language === languageSelector && (
                      <>
                        <option
                          value={audio.path ? audio.path : audio.url}
                          key={index}
                        >
                          {audio.version}
                        </option>
                      </>
                    )
                )}
              </select>
            </Grid>

            <Grid item xs={1}>
              <select
                value={videoVersionSelector}
                onChange={(e) => setVideoVersionSelector(e.target.value)}
                className={classes["AudioWorkSelector"]}
              >
                <option value="">select synced video version</option>
                {projectFile?.syncedVideoList?.map(
                  (audio, index) =>
                    audio.language === languageSelector && (
                      <>
                        <option
                          value={audio.path ? audio.path : audio.url}
                          key={index}
                        >
                          {audio.version}
                        </option>
                      </>
                    )
                )}
              </select>
            </Grid>
            <Grid item xs={1.05}>
              <input
                style={{ width: "150px", height: "23px" }}
                className={classes["AudioWorkNoteEditInputSub"]}
                type="text"
                placeholder="Enter version name"
                value={versionName}
                onChange={(e) => setVersionName(formatInputs(e.target.value))}
              />
            </Grid>
            <Grid item>
              <TaskButton
                onClick={() => finalSubmission()}
              // disabled={true}
              >
                Submit All
              </TaskButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SFIScreen;
