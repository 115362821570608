import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import classes from "../../Assets/Styles/Transcriber.module.css";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { primaryColor } from "../../utils/constants";

const VideoSlider = memo((props) => {
  const originalPlayer = useRef(null);
  const dialoguePlayer = useRef(null);
  const animationFrameRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [audioSpeed, setAudioSpeed] = useState(1);
  const [audioSource, setAudioSource] = useState('original');
  const [pauseTime, setPauseTime] = useState(0);

  const currentPlayer = audioSource === 'original' ? originalPlayer : dialoguePlayer;

  function formatTime(seconds, decimalPlaces = 2) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = (seconds % 60).toFixed(decimalPlaces);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(3 + decimalPlaces, "0")}`;
  }

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (currentPlayer.current) {
      currentPlayer.current.currentTime = time / 1000;
      setCurrentTime(time);
    }
  }, [currentPlayer]);

  const updateCurrentTime = useCallback(() => {
    if (currentPlayer.current) {
      setCurrentTime(currentPlayer.current.currentTime * 1000);
      animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
    }
  }, [currentPlayer]);

  const handlePlay = useCallback(() => {
    animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
  }, [updateCurrentTime]);

  const handlePause = () => {
    cancelAnimationFrame(animationFrameRef.current);
  };

  const handleDataLoaded = () => {
    if (currentPlayer.current) {
      setMaxTime(currentPlayer.current.duration * 1000);
    }
  };

  const handleProgress = () => {
    if (currentPlayer.current) {
      const buffer = currentPlayer.current.buffered;
      if (buffer.length > 0) {
        let currentBuffer = 0;
        const inSeconds = currentPlayer.current.currentTime;
        for (let i = 0; i < buffer.length; i++) {
          if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
            currentBuffer = i;
            break;
          }
        }
        setProgress(buffer.end(currentBuffer) * 1000 || 0);
      }
    }
  };

  const togglePlayPause = () => {
    if (currentPlayer.current) {
      if (isPlaying) {
        currentPlayer.current.pause();
        props.setPausePlay(false);
      } else {
        currentPlayer.current.play();
        props.setPausePlay(true);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    if (currentPlayer.current) {
      currentPlayer.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  const handleSpeedChange = (e) => {
    const newSpeed = parseFloat(e.target.value);
    if (currentPlayer.current) {
      currentPlayer.current.playbackRate = newSpeed;
      setAudioSpeed(newSpeed);
    }
  };

  const handleAudioSourceChange = (e) => {
    const newSource = e.target.value;
    const wasPlaying = isPlaying;
    const currentTimeValue = currentPlayer.current?.currentTime || 0;
    
    // Pause current player
    if (currentPlayer.current && isPlaying) {
      currentPlayer.current.pause();
    }
    
    setAudioSource(newSource);
    
    // Switch players and restore state
    setTimeout(() => {
      const newPlayer = newSource === 'original' ? originalPlayer : dialoguePlayer;
      if (newPlayer.current) {
        newPlayer.current.currentTime = currentTimeValue;
        if (wasPlaying) {
          newPlayer.current.play();
        }
      }
    }, 0);
  };

  useEffect(() => {
    const players = [originalPlayer.current, dialoguePlayer.current];
    players.forEach(player => {
      if (player) {
        player.addEventListener("play", handlePlay);
        player.addEventListener("pause", handlePause);
        player.addEventListener("loadeddata", handleDataLoaded);
        player.addEventListener("progress", handleProgress);
      }
    });

    return () => {
      players.forEach(player => {
        if (player) {
          player.removeEventListener("play", handlePlay);
          player.removeEventListener("pause", handlePause);
          player.removeEventListener("loadeddata", handleDataLoaded);
          player.removeEventListener("progress", handleProgress);
        }
      });
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [handlePlay]);

  useEffect(() => {
    props.displayAudioTime((currentTime / 1000).toFixed(3));
  }, [currentTime]);

  useEffect(() => {
    if (props.seekTimeIndex === 1 && currentPlayer.current) {
      currentPlayer.current.play();
      setIsPlaying(true);
      currentPlayer.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
    if (currentPlayer.current) {
      currentPlayer.current.currentTime = props.seekTime;
      setCurrentTime(props.seekTime * 1000);
    }
  }, [props.seekTimeIndex]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={5.85}
      xl={5.85}
      container
      direction="row"
      height="715px"
    >
      <Grid
        item
        container
        className={classes["AudioOutPut"]}
        direction="column"
      >
        <Grid item container className={classes["AudioOutPutVideo"]}>
          <video
            ref={originalPlayer}
            width="100%"
            height="100%"
            src={props.source}
            style={{ display: audioSource === 'original' ? 'block' : 'none' }}
          >
            <source type="video/mp4" />
          </video>
          <video
            ref={dialoguePlayer}
            width="100%"
            height="100%"
            src={props.audioSource}
            style={{ display: audioSource === 'dialogue' ? 'block' : 'none' }}
          >
            <source type="video/mp4" />
          </video>
        </Grid>
        <Grid
          item
          container
          className={classes["AudioOutPutController"]}
        >
          <Grid
            paddingLeft={1}
            paddingRight={1}
            item
            sx={{
              width: 3000,
              height: 20,
            }}
            bgcolor="#EBEBEB"
          >
            <VideoSeekSlider
              max={maxTime}
              currentTime={currentTime}
              bufferTime={progress}
              onChange={handleTimeChange}
              limitTimeTooltipBySides={true}
              secondsPrefix="00:"
              minutesPrefix="0:"
            />
          </Grid>

          <Grid
            item
            container
            direction="row"
            alignItems="center"
            gap={1}
            marginLeft={1}
            marginBottom={1}
          >
            <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
              <FastRewindIcon
                fontSize="large"
                onClick={() => handleTimeChange(currentTime - 100)}
              />
            </Grid>
            <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
              {isPlaying ? (
                <PauseCircleIcon
                  fontSize="large"
                  onClick={togglePlayPause}
                />
              ) : (
                <PlayCircleIcon
                  fontSize="large"
                  onClick={togglePlayPause}
                />
              )}
            </Grid>
            <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
              <FastForwardIcon
                fontSize="large"
                onClick={() => handleTimeChange(currentTime + 100)}
              />
            </Grid>
            <Grid
              item
              container
              className={classes["AudioOutPutShownTime"]}
              justifyContent="center"
              alignItems="center"
              marginRight={2}
              xs={1.5}
            >
              {formatTime(currentTime / 1000)}
            </Grid>
            
            <Grid>Audio:</Grid>
            <Grid item xs={1.5}>
              <select
                value={audioSource}
                onChange={handleAudioSourceChange}
                className={classes["AudioOutPutShowTimeOptionBox"]}
              >
                <option value="original">Original Audio</option>
                <option value="dialogue">Dialogue Only</option>
              </select>
            </Grid>

            <Grid>Speed:</Grid>
            <Grid item xs={1}>
              <select
                value={audioSpeed}
                onChange={handleSpeedChange}
                className={classes["AudioOutPutShowTimeOptionBox"]}
              >
                <option value="0.07">0.07x</option>
                <option value="0.1">0.1x</option>
                <option value="0.15">0.15x</option>
                <option value="0.25">0.25x</option>
                <option value="0.5">0.5x</option>
                <option value="1">1.0x</option>
                <option value="1.5">1.5x</option>
                <option value="2">2.0x</option>
                <option value="2.5">2.5x</option>
                <option value="3">3.0x</option>
              </select>
            </Grid>

            <Grid>Pause:</Grid>
            <Grid item xs={1}>
              <select
                value={pauseTime}
                onChange={(event) => setPauseTime(event.target.value)}
                className={classes["AudioOutPutShowTimeOptionBox"]}
              >
                <option value="0">0.0s</option>
                <option value="1">1.0s</option>
                <option value="2">2.0s</option>
                <option value="3">3.0s</option>
                <option value="4">4.0s</option>
                <option value="5">5.0s</option>
                <option value="6">6.0s</option>
                <option value="7">7.0s</option>
                <option value="8">8.0s</option>
              </select>
            </Grid>

            <Grid item sx={{ width: 200 }}>
              <Stack spacing={2} direction="row" alignItems="center">
                <VolumeDown />
                <Slider
                  value={volume}
                  onChange={handleVolumeChange}
                  min={0}
                  max={1}
                  step={0.1}
                  aria-label="Volume"
                  sx={{ color: `${primaryColor}` }}
                />
                <VolumeUp />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default VideoSlider;