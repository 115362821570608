export const GET_USERS2 = "/prestiges";

export const GET_COURSES = "/course";

export const GET_SESSIONS = "/session";

export const server = "https://y76-backend-dev-890718153427.us-central1.run.app"; //development
// export const server = "https://y76-backend-jshl5pugha-uc.a.run.app"; // production
// export const server = "http://localhost:4001"; // local

//------------------------------ Access Token
export const GET_ACESS_TOKEN =
  "https://vm-control-odn6jl2qqq-uc.a.run.app/get_access_token";

//------------------------------ USERS
export const GET_USERS = server + "/api/users/getusers";
export const UPDATE_USERS = server + "/api/users/update";
export const SIGN_IN_USER = server + "/api/users/login";
export const SIGN_UP_USER = server + "/api/users/register";

//----------------------------- Projects
export const GET_PROJECTS = server + "/api/projects/getprojects";

export const GET_PROJECTS_FOR_SINGLE_USER =
  server + "/api/projects/getprojectforuser";

export const GET_CHARACTERS_FOR_PROJECT =
  server + "/api/projects/getcharactersforproject";

export const AddUpdateDelete_CHARACTER_FOR_PROJECT =
  server + "/api/projects/updatecharacters";

export const UPLOAD_PROJECT_FILE = server + "/api/projects/upload";

export const TRANSCRIBE_PROJECT_FILE = server + "/api/projects/transcribe";

export const CREATE_PROJECT = server + "/api/projects/CreateProjectAndRole";

export const UPDATE_PROJECT = server + "/api/projects/update";

export const DELETE_PROJECT = server + "/api/projects/delete";

//----------------------------- Dialogues
export const GET_SPEECH_FOR_PROJECT =
  server + "/api/dialogues/getspeechsforproject";

export const GET_SPEECH_FOR_PROJECT_BY_PAGE =
  server + "/api/dialogues/getspeechsforprojectbypage";

export const GET_SPEECHS_DETAILS_FOR_PROJECT =
  server + "/api/dialogues/getspeechsdetailsforproject";

export const GET_SPEECH_POSITION_AROUND_TIME =
  server + "/api/dialogues/getspeechpositionaroundtime";

export const UPDATE_SPEECH_FOR_PROJECT =
  server + "/api/dialogues/updatespeechsforproject";

export const ADD_SPEECH_FOR_PROJECT = server + "/api/dialogues/addonespeech";
export const DELETE_SPEECH_FOR_PROJECT =
  server + "/api/dialogues/deleteonespeech";

export const GET_SPEECHS_FOR_SPEAKER =
  server + "/api/dialogues/getspeechsforspeaker";

export const UPDATE_SPEECH_RATING =
  server + "/api/dialogues/updateonespeechrating";


export const UPDATE_ONE_SPEECH = server + "/api/dialogues/updateonespeech";

//---------------------------- Dubbing Quality

export const CREATE_NEW_AUDIO = server + "/api/services/createnewaudio";

export const CREATE_MULTIPLE_AUDIO =
  server + "/api/services/createmultipleaudios";

export const CREATE_ALL_VOCALS_AUTOMATICALLY =
  server + "/api/services/createaudiofordialogues";

//---------------------------- Sound Effects

export const GET_ALL_SOUNDEFFECTS =
  server + "/api/soundeffects/getsoundeffectforproject";
export const UPDATE_ONE_SOUNDEFFECTS =
  server + "/api/soundeffects/updateonesoundeffect";
export const ADD_ONE_SOUNDEFFECTS =
  server + "/api/soundeffects/addonesoundeffect";
export const DELETE_ONE_SOUNDEFFECTS =
  server + "/api/soundeffects/deleteonesoundeffect";

//---------------------------- Lips Quality

export const GET_LIP = server + "/api/lipqualityassurance/getlipqa";
export const GET_LIP_BY_PAGE =
  server + "/api/lipqualityassurance/getlipqabypage";
export const UPDATE_ONE_LIP =
  server + "/api/lipqualityassurance/updateonelipqa";

//---------------------------- Speaker Face Identification

export const GET_ALL_FACE_DIMENSIONS =
  server + "/api/speakerfaceidentition/getspeakerfacestime";
export const DELETE_FACE_DIMENSION =
  server + "/api/speakerfaceidentition/deletefacedimensionsfordialogue";
export const ADD_FACE_DIMENSION =
  server + "/api/speakerfaceidentition/addfacedimensionsfordialogue";

//---------------------------- Final Qaulity Assurance

export const GET_ALL_QUALITYASSURANCE_VERSIONS =
  server + "/api/qualityassurances/getqafromproject";
export const GET_ALL_QUALITYASSURANCE_NOTES =
  server + "/api/qualityassurances/getqaforversion";
export const ADD_ONE_QUALITYASSURANCE_NOTE =
  server + "/api/qualityassurances/addoneqaforversion";
export const DELETE_ONE_QUALITYASSURANCE_NOTE =
  server + "/api/qualityassurances/deleteoneqaforversion";
export const UPDATE_ONE_QUALITYASSURANCE_NOTE =
  server + "/api/qualityassurances/updateoneqaforversion";

//---------------------------- Create a new audio cloud function api
export const CREATE_NEW_AUDIO_API =
  "https://us-central1-video-dub-e0a3e.cloudfunctions.net/createAudioApi";

export const CREATE_LIST_AUDIOS_TEXTS =
  "/api/services/createlistofaudiosandtexts";

//---------------------------- Transcribing Api
export const TRANSCRIBE = server + "/api/services/transcriberequest";
// export const TRANSCRIBE = server + "/api/projects/transcribe";
export const TRANSCRIBE_STATUS_CHECK =
  server + "/api/services/transcribestatus";

//---------------------------- Translating Api
export const TRANSLATE = server + "/api/services/translatedialogues";
export const UPLOAD_TRANSLATOR_VOICE = server + "/api/projects/upload"; // needs to be fixed

//---------------------------- Audio Chopping Api
export const AUDIO_CHOPPING = server + "/api/services/audiochopping";
export const AUDIO_CHOPPING_STATUS_CHECK =
  server + "/api/services/audiochoppingstatus";

//---------------------------- Audio Chopping Api
export const MERGING_AUDIO_API = server + "/api/services/mergingaudio";

export const MERGING_LIPS_API = server + "/api/services/merginglips";

export const MERGING_STATUS_CHECK = server + "/api/services/mergingaudio";

export const MERGING_VIDEO_API = server + "/api/services/mergingvideo";

//---------------------------- Voice Traning Api
export const VOICE_TRAINING = server + "/api/services/addvoiceidsforspeakers";
export const VOICE_TRAINING_STATUS_CHECK =
  server + "/api/services/voiceidsstatus";


export const AUTOMATIC_VOICE_PROCESSING = server + "/api/dialogues/automaticvoiceprocessing";
export const VALIDATE_EMOTIONS = server + "/api/dialogues/validateemotions";
//---------------------------- Video and Audio upload apis

export const UPLOAD_VIDEO_DRIVE = server + "/api/services/uploadvideofromdrive";
export const UPLOAD_VIDEO_DROPBOX =
  server + "/api/services/uploadvideofromdropbox";

export const UPLOAD_AUDIO_DRIVE = server + "/api/services/uploadaudiofromdrive";
export const UPLOAD_AUDIO_DROPBOX =
  server + "/api/services/uploadaudiofromdropbox";

export const DEMUCS_SPLIT = server + "/api/services/demucssplit";

export const REQUEST_POLL_TRANSCRIBE =
  server + "/api/services/requestPollTranscribe";

//-------------------------- faces services

export const FACE_DETECTOR = server + "/api/services/facedetector";
export const SYNC_LIPS = server + "/api/services/facelips";
